/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '@reduxjs/toolkit'
import { NOTIFICATION_STATUS } from '../../ui/Notification/types'
import {INotification} from "../../ui/Notification/types";
import i18n from "../../utils/i18n";

export interface IStateNotification {
	list: Array<INotification>
}

const addNewNotification = createAction<INotification>('NOTIFICATION/SET_NEW');
export const notificationClose = createAction<string>('NOTIFICATION/CLOSE')

const initialState:IStateNotification = {
	list: []
}

export default createReducer(initialState, (builder) => {
	builder
		.addCase(notificationClose, (state, action) => {
			state.list = state.list.filter((item) => item.id !== action.payload)
		})
		.addCase(addNewNotification, (state, action) => {
			state.list.push(action.payload)
		})
})

// ACTIONS
export const setNewNotification = (value: Omit<INotification, 'id'>) => {
	const date = new Date().toISOString()

	return (dispatch: any) => {
		dispatch(addNewNotification({
				...value,
				id: date
		}))
		setTimeout(() => {
			dispatch(notificationClose(date))
		}, 8000)
	}
}
export const setNotificationSuccess = (value: string) => {
	const notification = {
		title: i18n.notification.success,
		message: value,
		status: NOTIFICATION_STATUS.ok
	}
	return (dispatch: any) => {
		dispatch(setNewNotification(notification))
	}
}

export const setNotificationError = (value: string) => {
	const notification = {
		title: i18n.notification.error,
		message: value,
		status: NOTIFICATION_STATUS.error
	}
	return (dispatch: any) => {
		dispatch(setNewNotification(notification))
	}
}
