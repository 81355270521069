import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Icon from '../Icon/Icon'
import s from './menuTile.module.scss'
import {IconEnum} from "../Icon";

export interface IMenuTile {
	icon: IconEnum
	path: string,
	label: string
}

const MenuTile = ({ icon, path, label }: IMenuTile) => {
	return <Link to={path}>
		<div className={s.wrapper}>
			<Icon icon={icon} className={s.icon} />
			<p>{label}</p>
		</div>
	</Link>
}

export default MenuTile
