import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchGet } from '../../utils/fetch/useFetchGet';
import { paths, pathsApi } from '../../router/paths';
import Body from '../../ui/Body/body';
import GridRow from '../../ui/GridRow';
import GridCol from '../../ui/GridCol';
import TextItem from '../../ui/TextItem/textItem';
import i18n from '../../utils/i18n';
import { IPriceAttr, IProductFull } from '../../utils/types';
import Card from '../../ui/Card';
import { findValue } from '../ProductNew/utils/specIconEnum';
import Button from '../../ui/Button';
import { ButtonVariationEnum } from '../../ui/Button/types';
import { getDocLink, getProductImgSrc, HOST_TKO } from '../../utils/url';
import Link from '../../ui/Link';
import { IconEnum } from '../../ui/Icon';
import Details from '../../ui/Details/details';
import { getPrice } from '../../utils/getPrice';
import { getPriceSubCalculate } from '../../utils/priceSubCalculate';
import { useConfig } from '../../components/Config/config';
import Tag from '../../ui/Tag';
import { boolToReadable } from '../../utils/boolUtil';
import { TagEnum } from '../../ui/Tag/types';
import ButtonAnchor from '../../ui/Button/buttonAnchor';
import { GridAlignEnum } from '../../ui/GridRow/GridRow';
import s from './styles/product.module.scss';
import sRichText from '../../ui/RichTextEditor/styles/richTextEditor.module.scss'

const Product = () => {
    const { priceAttrGroups } = useConfig();
    const { id } = useParams();
    const navigate = useNavigate();
    const { data } = useFetchGet<IProductFull>({
        path: pathsApi.product + id,
        defaultValue: globalThis.history.state?.usr?.product?.id && globalThis.history.state.usr.product,
    });

    const getName = () => {
        return [data?.brand?.name, data?.name].filter(Boolean).join(' ');
    };

    if (!data) return <Body title={i18n.general.loading} isLoading={true} />;

    const editProduct = () => {
        navigate(paths.product + id + '/edit', { state: { product: data } });
    };

    const duplicateProduct = () => {
        const migratedData: Partial<IProductFull> = {
            brand: data.brand,
            category: data.category,
            description: data.description,
            techDescription: data.techDescription,
            individualPrice: data.individualPrice,
            // @ts-ignore
            filterItemList: data.filterItemList,
            howToUse: data.howToUse,
            imgZoom: data.imgZoom,
            keywords: data.keywords,
            specIcons: data.specIcons,
        };
        navigate(paths.productNew, { state: { product: migratedData } });
    };

    return (
        <Body title={getName()}>
            <GridRow gutter={16} className={s.root}>
                <GridCol col={12} className={s.buttonBlock}>
                    <ButtonAnchor
                        href={HOST_TKO + '/catalog/' + data.link}
                        target={'_blank'}
                        variation={ButtonVariationEnum.SOFT}
                    >
                        {i18n.product.linkOpenProduct}
                    </ButtonAnchor>
                    <Button onClick={editProduct} variation={ButtonVariationEnum.SOFT}>
                        {i18n.general.edit}
                    </Button>
                    <Button onClick={duplicateProduct} variation={ButtonVariationEnum.SOFT}>
                        {i18n.general.duplicate}
                    </Button>
                </GridCol>
                <GridCol col={8}>
                    <GridRow gutter={16}>
                        <GridCol col={6}>
                            <TextItem label={i18n.general.name} text={data.name} />
                        </GridCol>
                        <GridCol col={6}>
                            <TextItem label={i18n.product.subName} text={data.sub_name} />
                        </GridCol>

                        <GridCol col={6}>
                            <TextItem label={i18n.general.brand}>
                                {data.brand && <Link to={paths.brand + data.brand.id}>{data.brand.name}</Link>}
                            </TextItem>
                        </GridCol>
                        <GridCol col={6}>
                            <TextItem label={i18n.general.category}>
                                {data.category && (
                                    <Link to={paths.category + data.category.id}>{data.category.name}</Link>
                                )}
                            </TextItem>
                        </GridCol>
                        <GridCol col={6}>
                            <TextItem label={i18n.product.isVisible} text={data.deletedAt}>
                                <Tag type={!!data.deletedAt ? TagEnum.RED : TagEnum.GREEN}>
                                    {boolToReadable(!data.deletedAt)}
                                </Tag>
                            </TextItem>
                        </GridCol>
                        <GridCol col={6}>
                            <TextItem label={i18n.product.specIcons} text={data.specIcons?.map(findValue).join(', ')} />
                        </GridCol>
                    </GridRow>
                </GridCol>
                <GridCol col={4} className={s.sideBlock}>
                    <Card>
                        <TextItem label={i18n.general.updated} text={data.updatedAt} />
                    </Card>
                </GridCol>
                <GridCol col={12}>
                    <Details label={i18n.product.priceList} icon={IconEnum.MONEY}>
                        <GridRow gutter={8} alignRow={GridAlignEnum.TOP}>
                            <GridCol col={12}>
                                <TextItem label={i18n.price.individualPrice}>
                                    <Tag type={data.individualPrice ? TagEnum.GREEN : TagEnum.RED}>
                                        {boolToReadable(data.individualPrice)}
                                    </Tag>
                                </TextItem>
                            </GridCol>
                            {data.priceList?.map((price) => {
                                const subPrice =
                                    getPrice(
                                        getPriceSubCalculate({
                                            subValue: price.subValue,
                                            mainValue: price.mainValue,
                                            multiplier: price.multiplier,
                                        }),
                                    ) || '-';
                                return (
                                    <GridCol col={4} key={price.id}>
                                        <Card>
                                            <GridRow column gutter={12}>
                                                <TextItem label={i18n.price.sku} text={`${price.sku}`} />
                                                <TextItem label={i18n.product.isVisible}>
                                                    <Tag type={price.hidePrice ? TagEnum.RED : TagEnum.GREEN}>
                                                        {boolToReadable(!price.hidePrice)}
                                                    </Tag>
                                                </TextItem>
                                                <TextItem
                                                    label={i18n.price.mainPrice}
                                                    text={getPrice(price.mainValue) + ' / ' + price.mainUnits}
                                                />
                                                <TextItem
                                                    label={i18n.price.subPriceTitle}
                                                    text={subPrice + ' / ' + (price.subUnits || '-')}
                                                />
                                                <TextItem label={i18n.price.extra} text={price.extra} />
                                                <TextItem label={i18n.price.attr}>
                                                    {price.attrList?.map((attr: IPriceAttr) => {
                                                        const group = priceAttrGroups.find((group) =>
                                                            group.items.some((item) => item.id === attr.id),
                                                        );
                                                        return (
                                                            <Tag key={attr.id}>{group?.value + ': ' + attr.value}</Tag>
                                                        );
                                                    }) || '-'}
                                                </TextItem>
                                                <TextItem label={i18n.general.updated} text={price.updatedAt} />
                                            </GridRow>
                                        </Card>
                                    </GridCol>
                                );
                            })}
                        </GridRow>
                    </Details>
                    <Details label={i18n.product.imageList} icon={IconEnum.IMAGE}>
                        <GridRow gutter={8}>
                            <GridCol col={12}>
                                <TextItem label={i18n.product.zoomImg} text={data.deletedAt}>
                                    <Tag type={data.imgZoom ? TagEnum.GREEN : TagEnum.RED}>
                                        {boolToReadable(data.imgZoom)}
                                    </Tag>
                                </TextItem>
                            </GridCol>
                            {data.imageList
                                ?.sort((a, b) => a.order - b.order)
                                .map((img) => (
                                    <GridCol key={img.id} col={4}>
                                        <Card>
                                            <GridRow gutter={8} alignRow={GridAlignEnum.TOP}>
                                                <GridCol col={6}>
                                                    <img src={getProductImgSrc(img.src)} />
                                                </GridCol>
                                                <GridCol col={6}>
                                                    <GridRow column gutter={8}>
                                                        <TextItem label={i18n.images.alt} text={img.alt} />
                                                        <TextItem label={i18n.images.title} text={img.title} />
                                                    </GridRow>
                                                </GridCol>
                                            </GridRow>
                                        </Card>
                                    </GridCol>
                                ))}
                        </GridRow>
                    </Details>
                    <Details label={i18n.product.description} icon={IconEnum.EDIT}>
                        <Card>
                            <div dangerouslySetInnerHTML={{ __html: data.description }} className={sRichText.wrapper} />
                        </Card>
                    </Details>
                    <Details label={i18n.product.techDescription} icon={IconEnum.SETTING2}>
                        <GridRow gutter={12} alignRow={GridAlignEnum.TOP}>
                            {data.techDescription?.map((item, idx) => {
                                const [title, value] = item.split('::');
                                return (
                                    <GridCol key={idx} col={3}>
                                        <TextItem label={title} text={value} />
                                    </GridCol>
                                );
                            })}
                        </GridRow>
                    </Details>
                    <Details label={i18n.product.howToUse} icon={IconEnum.QUESTION}>
                        <Card>{data.howToUse && <div dangerouslySetInnerHTML={{ __html: data.howToUse }} className={sRichText.wrapper} />}</Card>
                    </Details>
                    <Details label={i18n.product.docList} icon={IconEnum.DOCS}>
                        <GridRow gutter={4}>
                            {data.docList?.map((doc) => (
                                <GridCol key={doc.id} col={3}>
                                    <Card>
                                        <GridRow column gutter={8}>
                                            <TextItem label={i18n.general.name} text={doc.name} />
                                            <ButtonAnchor
                                                target={'_blank'}
                                                href={getDocLink(doc.src)}
                                                variation={ButtonVariationEnum.SOFT}
                                            >
                                                {i18n.general.open}
                                            </ButtonAnchor>
                                        </GridRow>
                                    </Card>
                                </GridCol>
                            ))}
                        </GridRow>
                    </Details>
                    <Details label={i18n.product.filters} icon={IconEnum.FILTER}>
                        <p></p>
                    </Details>
                    <Details label={i18n.seo.name} icon={IconEnum.SEARCH}>
                        <GridRow column gutter={12}>
                            <TextItem label={i18n.seo.metaTitle} text={data.metaTitle} />
                            <TextItem label={i18n.seo.metaDescription} text={data.metaDescription} />
                            <TextItem label={i18n.seo.keywords} text={data.keywords} />
                        </GridRow>
                    </Details>
                </GridCol>
            </GridRow>
        </Body>
    );
};

export default Product;
