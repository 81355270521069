import React from 'react';
import ImagesUpload, { IImagesUpload } from './imagesUpload';
import { useFormState, useWatch, Controller } from 'react-hook-form';
import i18n from '../../utils/i18n';
import { IconEnum } from '../../ui/Icon';
import Details from '../../ui/Details/details';
import InputMessage, { InputMessageTypes } from '../../ui/InputMessage/inputMessage';
import { filesNames } from '../../utils/fetch/filesNames';
import ToggleButton from '../../ui/ToggleButton/ToggleButton';
import GridRow from '../../ui/GridRow';
import GridCol from '../../ui/GridCol';
import NotificationInline, {NotificationTypeEnum} from "../../ui/NotificationInline";

const ImageUploadProduct = (props: Omit<IImagesUpload, 'defaultAlt' | 'fileName'>) => {
    const brand = useWatch({ name: 'brand', control: props.control });
    const name = useWatch({ name: 'name', control: props.control });
    const imageField = useFormState({control: props.control, name: props.name, exact: true})
    const { errors } = useFormState({ control: props.control });

    return (
        <Details label={i18n.product.imageList} icon={IconEnum.IMAGE} error={!!errors?.[props.name]}>
            <GridRow gutter={8}>
                {imageField.dirtyFields[props.name] && <GridCol col={12}>
                    <NotificationInline type={NotificationTypeEnum.WARNING} text={i18n.images.dontForgetSaveProduct}/>
                </GridCol>}
                <GridCol col={12}>
                    <Controller
                        name={'imgZoom'}
                        control={props.control}
                        defaultValue={false}
                        render={({ field: { onChange, value } }) => (
                            <ToggleButton value={value} onClick={onChange} label={i18n.product.zoomImg} />
                        )}
                    />
                </GridCol>
                {!(brand && name) && (
                    <GridCol col={12}>
                        <NotificationInline type={NotificationTypeEnum.ERROR} text={i18n.doc.fillNameAndBrand}/>
                    </GridCol>
                )}
                <ImagesUpload
                    {...props}
                    defaultAlt={`Купить ${brand?.name} ${name} в Омске`}
                    fileName={filesNames({ brand: brand?.alias, name })}
                />
            </GridRow>
        </Details>
    );
};

export default ImageUploadProduct;
