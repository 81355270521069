import React from 'react';
import { richTextModules } from './utils';
import ReactQuill from 'react-quill';
import InputMessage, { InputMessageTypes } from '../InputMessage/inputMessage';
import s from './styles/richTextEditor.module.scss';

interface IRichTextEditor {
    value: string;
    onChange: () => void;
    error?: string;
    info?: string;
    warning?: string;
}

const RichTextEditor = ({ value, onChange, error, info, warning }: IRichTextEditor) => {
    return (
        <div className={s.root}>
            <link href="https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500&display=swap" rel="stylesheet"/>
            <div className={s.wrapper}>
                <ReactQuill theme="snow" value={value} onChange={onChange} modules={richTextModules}/>
            </div>
            <InputMessage message={error}/>
            <InputMessage message={info} type={InputMessageTypes.INFO}/>
            <InputMessage message={warning} type={InputMessageTypes.WARNING}/>
        </div>
    );
};

export default RichTextEditor;
