import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import i18n from '../../utils/i18n';
import I18n from '../../utils/i18n';
import Body from '../../ui/Body/body';
import GridRow from '../../ui/GridRow';
import Input from '../../ui/Input';
import { Controller, useForm } from 'react-hook-form';
import GridCol from '../../ui/GridCol';
import Dropdown from '../../ui/Dropdown';
import ToggleButton from '../../ui/ToggleButton/ToggleButton';
import Radio from '../../ui/Radio/Radio';
import { specIconOptions } from './utils/specIconEnum';
import RichTextEditor from '../../ui/RichTextEditor';
import 'react-quill/dist/quill.snow.css';
import Details from '../../ui/Details/details';
import { IconEnum } from '../../ui/Icon';
import Button from '../../ui/Button';
import { ButtonVariationEnum } from '../../ui/Button/types';
import sCommon from '../../utils/common.module.scss';
import { FileUploadType } from '../../components/ImagesUpload/types';
import ImageUploadProduct from '../../components/ImagesUpload/imageUploadProduct';
import FileUpload from '../../components/FileUpload';
import AliasLink from '../../components/AliasLink';
import ProductTechDescription from '../../components/ProductTechDescription';
import SeoProduct from '../../components/SeoProduct';
import { GridItemAlign } from '../../ui/GridCol/GridCol';
import PriceBlock from '../../components/PriceBlock';
import { convertArray } from '../../utils/convertArray';
import { useConfig } from '../../components/Config/config';
import {IImageDto, IPriceAttr, IProductFull, IProductPrice, IProductTechDescription} from '../../utils/types';
import { FetchApi } from '../../utils/fetch/fetchApi';
import { paths, pathsApi } from '../../router/paths';
import { parseHistoryState } from './utils/parseHistoryState';
import {AppDispatch} from "../../state";
import {setNotificationSuccess} from "../../state/reducers/notification";

const ProductNew = () => {
    const config = useConfig();
    const dispatch = useDispatch<AppDispatch>()
    const defaultValues = parseHistoryState();
    const { control, reset, handleSubmit, setValue } = useForm<any>({ defaultValues });
    const navigate = useNavigate();
    const [isLoading, toggleLoading] = useState<boolean>(false);

    const submit = handleSubmit(async (data) => {
        toggleLoading(true);
        const serializedData = {
            ...data,
            brand: data.brand?.id,
            category: data.category?.id,
            techDescription: data.techDescription?.map(
                (item: IProductTechDescription) => `${item.name}::${item.value}`,
            ),
            imageList: data.imageList?.map((item: IImageDto) => ({
                ...item,
                order: +item.order
            })),
            priceList: data.priceList?.map((item: IProductPrice) => ({
                ...item,
                attrList: item.attrList?.map((attr: IPriceAttr) => attr.id),
                mainUnits: item.mainUnits?.value,
                subUnits: item.subUnits?.value,
                sku: item.sku && +item.sku,
                subValue: +item.subValue,
            })),
            keywords: data.keywords?.join(','),
        };

        const response = await FetchApi.post<IProductFull>(pathsApi.productSave, serializedData);
        if (response?.id) {
            dispatch(setNotificationSuccess(i18n.product.savedSuccessfully))
            navigate(paths.product + response.id, { state: { product: response } });
        } else {
            toggleLoading(false);
        }
    });

    return (
        <Body title={defaultValues?.id ? i18n.product.updateProduct : i18n.menu.productNew}>
            <GridRow gutter={8}>
                <GridCol col={4} align={GridItemAlign.TOP}>
                    <Controller
                        name={'name'}
                        control={control}
                        rules={{ required: true }}
                        defaultValue={''}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                value={value}
                                onChange={onChange}
                                label={i18n.general.name}
                                infoMessage={i18n.product.nameHint}
                                error={!!error}
                            />
                        )}
                    />
                </GridCol>
                <GridCol col={4}>
                    <Controller
                        name={'sub_name'}
                        control={control}
                        rules={{ required: true }}
                        defaultValue={''}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                value={value}
                                onChange={onChange}
                                label={i18n.product.subName}
                                infoMessage={i18n.product.subNameHint}
                                error={!!error}
                            />
                        )}
                    />
                </GridCol>
                <GridCol col={12}>
                    <Controller
                        name={'visible'}
                        control={control}
                        defaultValue={true}
                        render={({ field: { onChange, value } }) => (
                            <ToggleButton value={value} onClick={onChange} label={i18n.product.isVisible} />
                        )}
                    />
                </GridCol>
                <GridCol col={8}>
                    <Controller
                        name={'brand'}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                value={value}
                                emptyFirstline
                                isValueObject
                                onChange={onChange}
                                options={convertArray(config.brands)}
                                label={i18n.general.brand}
                            />
                        )}
                    />
                </GridCol>
                <GridCol col={8}>
                    <Controller
                        name={'category'}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Dropdown
                                value={value}
                                emptyPlaceholder
                                isValueObject
                                onChange={onChange}
                                options={convertArray(config.categories.map(item => ({...item, name: ' - '.repeat(item.depth) + item.name})))}
                                error={!!error}
                                label={i18n.general.category}
                            />
                        )}
                    />
                </GridCol>
                <GridCol col={8}>
                    <AliasLink control={control} name={'alias'} setValue={setValue} />
                </GridCol>
                <GridCol col={12}>
                    <Controller
                        name={'specIcons'}
                        control={control}
                        defaultValue={[]}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Radio
                                value={value}
                                multiple
                                onClick={onChange}
                                options={specIconOptions}
                                label={i18n.product.specIcons}
                            />
                        )}
                    />
                </GridCol>
                <GridCol col={12}>
                    <PriceBlock name={'priceList'} control={control} />
                    <ImageUploadProduct type={FileUploadType.PRODUCT} name={'imageList'} control={control} />
                    <Controller
                        name={'description'}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Details label={i18n.product.description} icon={IconEnum.EDIT} error={!!error}>
                                <RichTextEditor value={value} onChange={onChange} error={error?.message} />
                            </Details>
                        )}
                    />
                    <ProductTechDescription control={control} name={'techDescription'} />
                    <Details label={i18n.product.howToUse} icon={IconEnum.QUESTION}>
                        <Controller
                            name={'howToUse'}
                            control={control}
                            defaultValue={''}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <RichTextEditor value={value} onChange={onChange} />
                            )}
                        />
                    </Details>
                    <FileUpload control={control} name={'docList'} defaultAlt={i18n.doc.techDescription} />
                    <Details label={i18n.product.filters} icon={IconEnum.FILTER}>

                    </Details>
                    <SeoProduct control={control} />
                </GridCol>
                <GridCol col={12} className={sCommon.buttonWrapper}>
                    <Button onClick={submit} isLoading={isLoading}>
                        {I18n.general.save}
                    </Button>
                    <Button variation={ButtonVariationEnum.SOFT} onClick={reset}>
                        {I18n.general.reset}
                    </Button>
                </GridCol>
            </GridRow>
        </Body>
    );
};

export default ProductNew;
