import i18n from '../utils/i18n';
import Invalidation from '../pages/Invalidation';
import ProductNew from '../pages/ProductNew';
import {IconEnum} from '../ui/Icon';
import Product from '../pages/Product';
import BrandAll from "../pages/BrandAll";
import CategoryAll from "../pages/CategoryAll";
import ProductAll from '../pages/ProductAll';
import Homepage from "../pages/Homepage";

interface IPath {
    path: string;
    icon?: IconEnum;
    label?: string;
    mainMenu?: boolean;
    homepage?: boolean;
    element: () => any;
}

export const paths = {
    product: '/product/',
    productAll: '/product/all',
    productNew: '/product/new',
    brand: '/brand/',
    brandAll: '/brand/all',
    brandNew: '/brand/new',
    categoryAll: '/category/all',
    category: '/category/',
    categoryNew: '/category/new',
    invalidation: '/invalidation'
};

export const pathsApi = {
    productAll: '/product',
    product: '/product/',
    uploadFile: '/files/upload',
    deleteFile: '/files/delete',
    config: '/config',
    productSave: '/product/save',
    brandAll: '/brand',
    categoryAll: '/category',
    invalidateAll: '/invalidation',
    cleanCache: '/invalidation/clean',
    cleanCacheAll: '/invalidation/force_clean_all'
};

export const routes: Array<IPath> = [
    {
        path: '/',
        element: Homepage
    },
    {
        path: paths.productNew,
        label: i18n.menu.productNew,
        icon: IconEnum.DOC_NEW,
        homepage: true,
        element: ProductNew,
    },
    {
        path: paths.productAll,
        label: i18n.menu.products,
        mainMenu: true,
        homepage: true,
        icon: IconEnum.DOCS,
        element: ProductAll,
    },
    {
        path: paths.product + ':id',
        element: Product,
    },
    {
        path: paths.product + ':id' + '/edit',
        element: ProductNew,
    },
    {
        path: paths.brandAll,
        label: i18n.menu.brand,
        mainMenu: true,
        homepage: true,
        icon: IconEnum.BRAND,
        element: BrandAll,
    },
    {
        path: paths.categoryAll,
        label: i18n.menu.category,
        mainMenu: true,
        homepage: true,
        icon: IconEnum.CATALOG,
        element: CategoryAll,
    },
    {
        path: paths.invalidation,
        label: i18n.menu.cacheClean,
        mainMenu: true,
        homepage: true,
        icon: IconEnum.DATABASE,
        element: Invalidation,
    },
];
