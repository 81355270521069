import notification from './reducers/notification'
import { configureStore } from '@reduxjs/toolkit'

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

const store = configureStore({
	reducer: {
		notification: notification,
	},
	devTools: process.env.NODE_ENV !== 'production'
})


export default store
