import React from 'react';
import { useNavigate } from 'react-router-dom';
import Details from "../../../ui/Details/details";
import GridCol from "../../../ui/GridCol";
import Dropdown from "../../../ui/Dropdown";
import {convertArray} from "../../../utils/convertArray";
import i18n from "../../../utils/i18n";
import GridRow from "../../../ui/GridRow";
import {useConfig} from "../../../components/Config/config";
import { Controller, useForm } from 'react-hook-form';
import Input from "../../../ui/Input";
import sCommon from "../../../utils/common.module.scss";
import Button from "../../../ui/Button";
import I18n from "../../../utils/i18n";
import {ButtonVariationEnum} from "../../../ui/Button/types";
import {paths} from "../../../router/paths";
import {IConfig} from "../../../components/Config/types";

const getDefaultValuesFromUrl = (config: IConfig): Record<string, any> => {
    const values = Object.fromEntries(new URLSearchParams(globalThis.location.search));
    return {
        ...values,
        brand: values.brand && config.brands.find(item => item.id === +values.brand),
        category: values.category && config.categories.find((item) => item.id === +values.category)
    }
}

const Filters = () => {
    const config = useConfig();

    const { control, handleSubmit, reset} = useForm<any>({defaultValues: getDefaultValuesFromUrl(config)});
    const navigate = useNavigate()

    const submit = handleSubmit((data) => {
        const searchParams = new URLSearchParams()
        Object.entries(data).forEach(([key, value]) => {
            if (value) {
                // @ts-ignore
                searchParams.set(key, typeof value === 'string' ? value.trim() : value.id)
            }
        })
        if (searchParams.size) {
            navigate(paths.productAll + '?' + searchParams.toString())
        }
    })

    const handleReset = () => {
        reset({})
        navigate(paths.productAll)
    }

    return (
        <Details label={i18n.general.filter}>
            <GridRow gutter={8}>
                <GridCol col={3}>
                    <Controller
                        name={'brand'}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                value={value}
                                emptyFirstline
                                isValueObject
                                onChange={onChange}
                                options={convertArray(config.brands.sort((a, b) => new Intl.Collator('en').compare(a.name, b.name)))}
                                label={i18n.general.brand}
                            />
                        )}
                    />
                </GridCol>
                <GridCol col={3}>
                    <Controller
                        name={'category'}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                value={value}
                                emptyPlaceholder
                                isValueObject
                                onChange={onChange}
                                options={convertArray(config.categories.map(item => ({...item, name: ' - '.repeat(item.depth) + item.name})))}
                                label={i18n.general.category}
                            />
                        )}
                    />
                </GridCol>
                <GridCol col={3}>
                    <Controller
                        name={'name'}
                        control={control}
                        defaultValue={''}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                value={value}
                                onChange={onChange}
                                label={i18n.general.name}
                            />
                        )}
                    />
                </GridCol>
                <GridCol col={12} className={sCommon.buttonWrapper}>
                    <Button onClick={submit} variation={ButtonVariationEnum.SOFT}>
                        {I18n.general.save}
                    </Button>
                    <Button variation={ButtonVariationEnum.SOFT} onClick={handleReset}>
                        {I18n.general.reset}
                    </Button>
                </GridCol>
            </GridRow>
        </Details>
    );
};

export default Filters;