import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import Icon, { IconEnum } from '../Icon';
import Grid from '../GridRow';
import classes from './styles/notificationInline.module.scss';
import { GridAlignEnum } from '../GridRow/GridRow';
import {NotificationTypeEnum} from "./types";

interface INotification extends HTMLAttributes<HTMLDivElement> {
    text: string;
    type: NotificationTypeEnum;
}

const NotificationInline = ({ text, type = NotificationTypeEnum.SUCCESS, ...rest }: INotification) => {
    const styles = clsx(
        classes.main,
        type === NotificationTypeEnum.ERROR && classes.error,
        type === NotificationTypeEnum.SUCCESS && classes.success,
        type === NotificationTypeEnum.WARNING && classes.warning,
    );

    const iconType = (() => {
        switch (type) {
            case NotificationTypeEnum.ERROR:
                return IconEnum.ERROR;
            case NotificationTypeEnum.SUCCESS:
                return IconEnum.OK;
            case NotificationTypeEnum.WARNING:
                return IconEnum.WARNING;
            default:
                return null;
        }
    })();

    return (
        <Grid gutter={8} alignRow={GridAlignEnum.MIDDLE} className={styles} {...rest}>
            {iconType && <Icon className={classes.icon} icon={iconType} />}
            {text}
        </Grid>
    );
};

export default NotificationInline;
