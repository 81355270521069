import React, { useState } from 'react';
import DottedButton from '../../ui/DottedButton';
import i18n from '../../utils/i18n';
import GridRow from '../../ui/GridRow';
import s from './styles/imagesUpload.module.scss';
import ImageUploadPopUp from './modules/imageUploadPopUp';
import { FileUploadType } from './types';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import Input from '../../ui/Input';
import Card from '../../ui/Card';
import DeleteButton from './modules/deleteButton';
import GridCol from '../../ui/GridCol';
import { getProductImgSrc } from '../../utils/url';

export interface IImagesUpload {
    type: FileUploadType;
    control: Control;
    name: string;
    defaultAlt?: string;
    fileName?: string;
}

const ImagesUpload = ({ type, control, name, defaultAlt = '', fileName }: IImagesUpload) => {
    const [isPopupOpen, togglePopUp] = useState<boolean>(false);
    const { fields, append, remove } = useFieldArray({
        control,
        name,
        rules: { required: true },
    });

    const handleSuccessUpload = (fileName: string) => {
        append({ src: fileName });
    };

    return (
        <GridCol col={12}>
            <GridRow className={s.root} gutter={8}>
                {fields?.map((item, idx) => (
                    <Card key={item.id}>
                        <GridRow className={s.card} gutter={4}>
                            <GridRow gutter={8} column>
                                <img src={getProductImgSrc(item.src)} />
                                <DeleteButton
                                    handleDelete={() => remove(idx)}
                                    type={FileUploadType.PRODUCT}
                                    src={item.src}
                                />
                            </GridRow>
                            <GridRow column gutter={8}>
                                <Controller
                                    name={`${name}.${idx}.alt`}
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={defaultAlt}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Input
                                            value={value}
                                            onChange={onChange}
                                            label={i18n.images.alt}
                                            infoMessage={i18n.images.hintUsedForGoogle}
                                            error={!!error}
                                        />
                                    )}
                                />
                                <Controller
                                    name={`${name}.${idx}.title`}
                                    control={control}
                                    defaultValue={''}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Input
                                            value={value}
                                            onChange={onChange}
                                            label={i18n.images.title}
                                            infoMessage={i18n.images.hintUsedForGoogle}
                                            error={!!error}
                                        />
                                    )}
                                />
                                <Controller
                                    name={`${name}.${idx}.order`}
                                    control={control}
                                    defaultValue={1}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Input
                                            value={value}
                                            type={'number'}
                                            onChange={onChange}
                                            label={i18n.general.order}
                                            error={!!error}
                                        />
                                    )}
                                />
                            </GridRow>
                        </GridRow>
                    </Card>
                ))}
                <DottedButton className={s.addButton} onClick={() => togglePopUp(true)}>
                    {i18n.images.upload + i18n.images.name}
                </DottedButton>
            </GridRow>
            {isPopupOpen && (
                <ImageUploadPopUp
                    closePopUp={() => togglePopUp(false)}
                    type={type}
                    handleSuccess={handleSuccessUpload}
                    titleName={i18n.images.name}
                    fileName={fileName}
                />
            )}
        </GridCol>
    );
};

export default ImagesUpload;
