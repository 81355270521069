export enum NOTIFICATION_STATUS {
	error = 'error',
	warning = 'warning',
	ok = 'ok'
}

export interface INotification {
	title: string,
	message: string,
	id: string,
	status: NOTIFICATION_STATUS
}